<template>
  <div>
    <div class="main">
      <canvas :id="id" class="disabled" :height="height" :width="height * 2">
      </canvas>
      <div
        class="aoth"
        :style="[
          { left: height - 25 + 'px' },
          { bottom: height / 2 - 25 + 'px' },
        ]"
      >
        <div class="name">{{ name }}</div>
        <div class="value">{{ parseFloat(value * 100).toFixed(2) }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "canvasdash",
  components: {},
  data() {
    return {};
  },
  props: {
    value: {
      type: Number,
      default: 1,
    },
    color1: {
      type: String,
      default: "",
    },
    color2: {
      type: String,
      default: "",
    },
    shadowcolor: {
      type: String,
      default: "",
    },
    shadowcolor2: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: "id0",
    },
    name: {
      type: String,
      default: "",
    },
  },
  created() {
    // this.drawChart(this.value,3.1)
  },

  watch: {
    value(val) {
      this.drawChart(val, 3.14);
    },
    name() {
      this.drawChart(this.value, 3.14);
    },
  },

  mounted() {
    this.drawChart(this.value, 3.14);
  },

  methods: {
    drawChart(percentV, sR) {
      let percent;
      if (isNaN(percentV)) {
        percent = 0;
      } else {
        percent = parseFloat((percentV * 100).toFixed(2));
      }

      console.log(percent);

      let that = this;
      if (percent < 0 || percent > 100) {
        return;
      }
      if (sR < Math.PI / 2 || sR >= (3 / 2) * Math.PI) {
        return;
      }

      var canvas = document.querySelector("#" + this.id),
        cxt = canvas.getContext("2d"),
        cWidth = canvas.width,
        cHeight = canvas.height,
        baseColor = "#e4e8eb",
        coverColor = this.color1,
        gradient = cxt.createLinearGradient(0, 0, canvas.width, 0),
        PI = Math.PI,
        // eslint-disable-next-line no-redeclare
        sR = sR || (1 / 2) * PI; // 默认圆弧的起始点弧度为π/2

      /** 2022/3/23
       *作者:Barney Jiang
       *功能:设置渐变
       */
      gradient.addColorStop(0, this.color1);
      // gradient.addColorStop(0.5, this.color2);
      gradient.addColorStop(1, this.color2);

      var finalRadian = sR + ((PI + (PI - sR) * 2) * percent) / 100; // 红圈的终点弧度
      var step = (PI + (PI - sR) * 2) / 100; // 一个1%对应的弧度大小
      var text = 0; // 显示的数字

      window.requestAnimationFrame(paint);
      function paint() {
        cxt.clearRect(0, 0, cWidth, cHeight);
        var endRadian = sR + text * step;
        // 画灰色圆弧
        drawCanvas(
          cWidth / 2,
          cHeight,
          cHeight - 10,
          sR,
          sR + (PI + (PI - sR) * 2),
          baseColor,
          5,
          0,
          0,
          0,
          ""
        );
        // 画彩色圆弧
        drawCanvas(
          cWidth / 2,
          cHeight,
          cHeight - 10,
          sR,
          endRadian,
          coverColor,
          5,
          8,
          0,
          4,
          that.shadowcolor
        );

        // 画圆头
        // 红色圆头其实就是一个圆
        var angle = 2 * PI - endRadian; // 转换成逆时针方向的弧度（三角函数中的）
        let xPos = Math.cos(angle) * (cHeight - 10) + cWidth / 2; // 红色圆 圆心的x坐标
        let yPos = -Math.sin(angle) * (cHeight - 10) + cHeight; // 红色圆 圆心的y坐标
        drawCanvas(
          xPos,
          yPos,
          2,
          0,
          2 * PI,
          coverColor,
          5,
          8,
          0,
          0,
          that.shadowcolor2
        );

        //画外层的圈  需要传入有透明度的颜色
        drawCanvas(
          xPos,
          yPos,
          2,
          0,
          2 * PI,
          that.shadowcolor2,
          15,
          0,
          0,
          0,
          that.shadowcolor2
        );

        // 数字
        cxt.fillStyle = coverColor;
        cxt.font = "40px PT Sans";
        // var textWidth = cxt.measureText(text + "%").width;
        // cxt.fillText(text+'%', cWidth/2 - textWidth/2, cHeight/2 + -5);
        text++;

        if (endRadian.toFixed(2) < finalRadian.toFixed(2)) {
          window.requestAnimationFrame(paint);
        }
      }

      function drawCanvas(
        x,
        y,
        r,
        sRadian,
        eRadian,
        color,
        lineWidth,
        shadow,
        shadowx,
        shadowy,
        shadowColor
      ) {
        cxt.beginPath();
        cxt.lineCap = "round";
        cxt.strokeStyle = color;
        cxt.shadowOffsetX = shadowx; // 阴影横向位移
        cxt.shadowOffsetY = shadowy; // 阴影纵向位移
        cxt.shadowColor = shadowColor; // 阴影颜色
        cxt.shadowBlur = shadow; // 阴影模糊范围
        cxt.lineWidth = lineWidth;
        cxt.arc(x, y, r, sRadian, eRadian, false);
        cxt.stroke();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  position: relative;
  .disabled {
    overflow: hidden;
    border-bottom: 1px solid #ecf0f3;
  }

  .aoth {
    position: absolute;
    text-align: center;

    .name {
      font-size: 14px;
      color: #898a8c;
      line-height: 22px;
      font-weight: 400;
    }
    .value {
      font-size: 14px;
      color: rgba(51, 51, 51, 0.85);
      text-align: center;
      line-height: 18px;
      font-weight: 500;
    }
  }
}
</style>
