<template>
  <div>
    <div class="main">
      <div
        :id="id"
        :style="{
          width: height + 'px',
          minWidth: height + 'px',
          height: height + 'px',
          margin: '0 auto',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { center } from "@antv/g2plot/lib/plots/sankey/sankey";
export default {
  name: "ringChart",
  components: {},
  data() {
    return {};
  },
  props: {
    height: {
      type: Number,
      default: 200,
    },
    subColor: {
      type: String,
      default: "",
    },
    color1: {
      type: String,
      default: "",
    },
    color2: {
      type: String,
      default: "",
    },
    color3: {
      type: String,
      default: "",
    },
    shadowcolor: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    subtext: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: "annular",
    },
  },

  watch: {
    value(vol) {
      this.drawChart();
    },
  },

  created() {},

  mounted() {
    this.drawChart();
  },

  methods: {
    drawChart() {
      console.log(this.value);

      let percent;
      if (isNaN(this.value)) {
        percent = 0;
        this.subtext == "0%";
      } else {
        percent = parseFloat(parseFloat(this.value).toFixed(2));
      }
      var myChart = this.$echarts.init(document.getElementById(this.id));
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.name,
          // text: "就是等你多久啊收到了",
          textStyle: {
            color: "#898A8C",
            fontSize: 14,
            fontWeight: 400,
          },
          subtext: percent.toFixed(2) + "%",
          // subtext: "10000%",
          subtextStyle: {
            color: "rgba(51,51,51,0.85)",
            fontSize: 14,
            fontWeight: 500,
          },
          itemGap: 10, // 主副标题距离
          textAlign: "center",
          textVerticalAlign: "middle",
          left: "47%",
          top: "42%",
          // left: "center",
          // top: "middle",
        },
        angleAxis: {
          max: 100, // 满分
          clockwise: true, // 逆时针
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        polar: {
          center: ["50%", "50%"],
          radius: ["60%", "100%"], //图形大小
        },
        series: [
          {
            type: "bar",
            data: [
              {
                name: this.name,
                value: percent,
                itemStyle: {
                  normal: {
                    color: this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                      {
                        offset: 0,
                        color: this.color1,
                      },
                      {
                        offset: 0.5,
                        color: this.color2,
                      },
                      {
                        offset: 1,
                        color: this.color3,
                      },
                    ]),
                    shadowBlur: 12,
                    shadowColor: this.shadowcolor,
                    shadowOffsetX: 0,
                    shadowOffsetY: 6,
                  },
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 16,
            barGap: "-100%", // 两环重叠
            z: 2,
          },
          {
            // 灰色环
            type: "bar",
            data: [
              {
                value: 100,
                itemStyle: {
                  color: this.subColor,
                  shadowBlur: 0,
                  shadowOffsetY: 0,
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 16,
            barGap: "-100%", // 两环重叠
            z: 1,
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  text-align: center;
}
</style>
